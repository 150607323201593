'use client';
import {
  PropsWithChildren,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { HiOutlineChevronDown } from 'react-icons/hi';

export function Collapsed({
  container,
  className,
  children,
}: PropsWithChildren<{
  container: ReactNode;
  className?: string;
}>) {
  const [isExpanding, setIsExpanding] = useState(false);
  const [isCollapsing, setIsCollapsing] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setContentHeight(contentRef.current?.scrollHeight || 0);
  }, [children]);

  useEffect(() => {
    if (isExpanding) {
      setContentHeight(contentRef.current?.scrollHeight || 0);
      setTimeout(() => {
        setIsExpanding(false);
        setIsExpanded(true);
      }, 200);
    }
  }, [isExpanding]);

  useEffect(() => {
    if (isCollapsing) {
      setContentHeight(0);
      setTimeout(() => {
        setIsCollapsing(false);
        setIsExpanded(false);
      }, 200);
    }
  }, [isCollapsing]);

  return (
    <div className={classNames('w-full flex flex-col', className)}>
      <button
        onClick={() =>
          isExpanded ? setIsCollapsing(true) : setIsExpanding(true)
        }
        className="block"
      >
        <div className="flex items-center">
          {container}
          <div className="p-2">
            <HiOutlineChevronDown
              className={classNames('transition-transform ', {
                'transform rotate-180': isExpanded || isExpanding,
                'transform rotate-0': !(isExpanded || isExpanding),
              })}
            />
          </div>
        </div>
      </button>
      <div
        className="overflow-hidden transition-height duration-200"
        style={{
          height: isExpanding || isCollapsing ? contentHeight : 'auto',
        }}
      >
        {isExpanding || isCollapsing || isExpanded ? (
          <div ref={contentRef}>{children}</div>
        ) : null}
      </div>
    </div>
  );
}
