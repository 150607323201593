'use client';
import { ReactNode, useRef } from 'react';
import { Dropdown } from '../dropdown';
import { Option } from './models';
import { SelectOptions } from './select-options';
import { SelectContainer } from './select-container';

type Props = {
  ariaLabel: string;
  value?: string;
  placeholder?: ReactNode;
  options: Option[];
  selectClassName?: string;
  optionsClassName?: string;
  optionClassName?: string;
  ghost?: boolean;
  tight?: boolean;
  disabled?: boolean;
  round?: boolean;
  chevron?: boolean;
  search?: boolean;
  searchPlaceholder?: string;
  noResultsMessage?: ReactNode;
  onChange: (value?: string) => void;
};

export function Select({
  ariaLabel,
  value,
  placeholder,
  options,
  selectClassName,
  optionsClassName,
  optionClassName,
  ghost,
  tight,
  disabled,
  search,
  searchPlaceholder,
  noResultsMessage,
  round = true,
  chevron = true,
  onChange,
}: Props) {
  const searchRef = useRef<HTMLInputElement>(null);
  const selectedOption = options.find((option) => option.value === value);

  return (
    <Dropdown
      ariaLabel={ariaLabel}
      ghost={ghost}
      tight={tight}
      disabled={disabled}
      round={round}
      className={selectClassName}
      container={
        <SelectContainer
          selectedOption={selectedOption}
          placeholder={placeholder}
          chevron={chevron}
        />
      }
      onOpen={() => searchRef.current?.focus()}
    >
      <SelectOptions
        ref={searchRef}
        options={options}
        value={value}
        optionsClassName={optionsClassName}
        optionClassName={optionClassName}
        onChange={onChange}
        search={search}
        placeholder={searchPlaceholder}
        noResultsMessage={
          <div className="py-0.5 xs:py-1 px-2 xs:px-3">{noResultsMessage}</div>
        }
      />
    </Dropdown>
  );
}
