export function formatDate(date: Date): string {
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
}

function zeroLead(value: number): string {
  return value.toString().padStart(2, '0');
}

export function formatDateTime(date: Date): string {
  return `${formatDate(date)} ${date.getHours()}:${zeroLead(date.getMinutes())}:${zeroLead(date.getSeconds())}`;
}
